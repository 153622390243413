<template>
    <small v-if="product.is_nos"><u>{{ $t('models.ProductStock.nos_restocked_frequently') }}</u></small>
    <small v-else><u>{{ $t('models.ProductStock.availability')}}</u>: {{product.availability.quantity}}</small>
</template>

<script>
export default {
    props: [
        'product',
        'short'
    ],
};
</script>
